import {
    required
} from 'vuelidate/lib/validators';

export const validationRules = {
    firstname: {
        required
    },
    lastname: {
        required
    }
};
